import * as React from "react"

import { cva } from "../cva.ts"
import { useSidebar } from "./sidebar-root.tsx"

export function Sidebar({ children }: { children: React.ReactNode }) {
  const { open } = useSidebar()

  return (
    <>
      <Overlay />
      <aside className={classForSidebar({ open })}>{children}</aside>
    </>
  )
}

const classForSidebar = cva(
  [
    "w-[var(--sidebar-width-mobile)] md:w-[var(--sidebar-width)] fixed h-full overflow-x-hidden [overflow-y:overlay] [scroll-behavior:smooth] z-[1]",
    "flex flex-col gap-4",
    "text-sm/4 text-gray-11 bg-gray-2",
    "bshadow-r bshadow-gray-4",
    "[transition:translate_var(--sidebar-transition-duration)]",
    "select-none",
  ],
  {
    variants: {
      open: {
        false: [
          "[translate:-15rem_0]", // -240px (sidebar total width + sidebar collapsed width)
          "md:[translate:-11.25rem_0]", // -180px (sidebar total width + sidebar collapsed width)
        ],
      },
    },
  },
)

function Overlay() {
  const { open, toggleSidebar } = useSidebar()
  return <div className={classForOverlay({ open })} onClick={toggleSidebar} />
}

const classForOverlay = cva(
  "fixed inset-0 md:hidden bg-black/80 transition-all ease-in-out duration-500",
  {
    variants: {
      open: {
        true: "visible opacity-100",
        false: "invisible opacity-0",
      },
    },
  },
)

export { Content } from "./sidebar-content.tsx"
export { Footer } from "./sidebar-footer.tsx"
export { Header } from "./sidebar-header.tsx"
export { Menu, SubMenu } from "./sidebar-menu.tsx"
export { Root, useSidebar } from "./sidebar-root.tsx"
export { Toggle } from "./sidebar-toggle.tsx"

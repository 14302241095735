import * as UI from "@iyk/ui"
import * as Route from "../../Route.ts"

import { LoaderResponse } from "@iyk/remix"
import { Form, Link, Outlet } from "@remix-run/react"
import { Effect } from "effect"
import { authenticated, Remix } from "../../main.server.ts"

export const loader = Remix.loader.middleware(authenticated.loader()).handler(() =>
  Effect.gen(function* () {
    return LoaderResponse.Ok(null)
  }),
)

export default () => {
  return (
    <UI.Sidebar.Root>
      <UI.Sidebar.Sidebar>
        <UI.Sidebar.Header>IYK Admin</UI.Sidebar.Header>
        <UI.Sidebar.Content>
          <UI.Sidebar.Menu label="Pages">
            <Link to={Route.chipsIngestV1}>V1 Chips Ingest</Link>
            <Link to={Route.tagGroupsExplode}>V1 Chip Group Explode</Link>
            <Link to={Route.chipsCheckDuplicates}>V1 Chips Check Duplicates</Link>
            <Link to={Route.chipsMigrate}>Migrate V1 to V2 Chips</Link>
            <Link to={Route.chipsIngestV2}>V2 Chips Ingest</Link>
            <Link to={Route.chipsGroups}>V2 Chip Groups</Link>
            <Link to={Route.solanaOrders}>Solana Orders</Link>
            <Link to={Route.postcardsSetVideo}>Postcards Set Video</Link>
          </UI.Sidebar.Menu>
        </UI.Sidebar.Content>

        <UI.Sidebar.Footer>
          <Form method="POST" action="/logout">
            <UI.Button type="submit" kind="solid" size="md">
              Logout
            </UI.Button>
          </Form>
        </UI.Sidebar.Footer>
      </UI.Sidebar.Sidebar>

      <main>
        <Outlet />
      </main>
      <UI.Toaster />
    </UI.Sidebar.Root>
  )
}

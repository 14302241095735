import { cva } from "../cva.ts"
import { useSidebar } from "./sidebar-root.tsx"

export function Content({ children }: Content.Props) {
  const { open } = useSidebar()

  return (
    <main data-ui="content" className={classForContent({ open })}>
      {children}
    </main>
  )
}

const classForContent = cva(
  "grow flex flex-col gap-6 [transition:all_var(--sidebar-transition-duration)]",
  {
    variants: {
      open: {
        true: "",
        false: [
          "translate-x-[calc(var(--sidebar-width-mobile)-var(--sidebar-width-collapsed))]",
          "md:translate-x-[calc(var(--sidebar-width)-var(--sidebar-width-collapsed))]",
        ],
      },
    },
  },
)

export namespace Content {
  export type Props = {
    children: React.ReactNode
  }
}

import * as Icon from "@iyk/icons"

import { classForButton } from "../"
import { useSidebar } from "./sidebar-root.tsx"
import { Toggle } from "./sidebar-toggle.tsx"

export function Header({ children }: Header.Props) {
  const { open } = useSidebar()

  return (
    <header data-ui="header" className="shrink-0 pt-1.5 px-1.5 flex items-center justify-between">
      <div className="grow">{children}</div>
      <Toggle className={classForButton({ size: "sm", kind: "ghost" })}>
        {open ? <Icon.SidebarClose /> : <Icon.SidebarOpen />}
      </Toggle>
    </header>
  )
}

export namespace Header {
  export type Props = {
    children: React.ReactNode
  }
}

import * as React from "react"

import { cva } from "../cva.ts"
import { mapChildren } from "../to-wrapped-children.ts"
import { useSidebar } from "./sidebar-root.tsx"

const classForSidebarLinkChildren = cva([
  "[&>a]:overflow-hidden",
  "[&>a]:flex [&>a]:items-center [&>a]:gap-2",
  "[&>a]:p-1.5 [&>a]:rounded-md",
  "[&>a:hover]:bg-gray-4 [&>a:hover]:text-gray-12",
  "[&>a]:[transition:width_var(--sidebar-transition-duration),background-color_0.15s]",
  "[&>a>svg]:shrink-0",
  "[&>a[aria-current]]:bg-gray-4 [&>a[aria-current]]:text-gray-12",
])

// #region Sidebar Menu

export const Menu = ({ children, className, label, ...props }: Menu.Props) => {
  const { open } = useSidebar()

  return (
    <div data-ui="SidebarMenu" className={classForMenu({ className })} {...props}>
      {label ? <h2 className={classForMenuLabel({ open })}>{label}</h2> : null}
      <nav className={classForSidebarMenuNav({ open })}>
        {/* checks for valid sidebar menu links (like <a>, <Link>, etc.) */}
        {mapChildren(children, (child, childKey) =>
          React.isValidElement(child)
            ? React.cloneElement(child, {
                key: childKey,
                /* wraps text within links in <span> */
                children: mapChildren(child.props.children, (grandChild, grandKey) =>
                  React.isValidElement(grandChild) ? (
                    grandChild
                  ) : (
                    <span key={grandKey} className={classForLinkContent()}>
                      {grandChild}
                    </span>
                  ),
                ),
              })
            : child,
        )}
      </nav>
    </div>
  )
}

const classForLinkContent = cva("grow truncate transition-opacity")

export namespace Menu {
  export interface Props extends React.ComponentProps<"div"> {
    label?: React.ReactNode
    children?: React.ReactElement<{ children?: React.ReactNode }>[]
  }
}

const classForMenu = cva("flex flex-col gap-1 min-w-0 w-full px-1.5")

const classForMenuLabel = cva("pl-1.5 text-xs", {
  variants: {
    open: {
      true: "visible",
      false: "invisible",
    },
  },
})

const classForSidebarMenuNav = cva(["flex flex-col gap-px", classForSidebarLinkChildren()], {
  variants: {
    open: {
      true: ["items-stretch", "[&>a]:w-full"],
      false: ["items-stretch", "[&>a]:w-7"],
    },
  },
})

// #endregion

// #region Sidebar SubMenu

export const SubMenu = ({ children }: SubMenu.Props) => {
  const { open } = useSidebar()

  return (
    <ul data-ui="sub-menu" className={classForSubMenu({ open })}>
      {[children].flat().map((child, key) => (
        <li key={key} className={classForSidebarLinkChildren({ className: "pl-1" })}>
          {child}
        </li>
      ))}
    </ul>
  )
}

export namespace SubMenu {
  export type Props = {
    children: React.ReactNode
  }
}

const classForSubMenu = cva(["pl-1.5 ml-3.5", "flex flex-col gap-px", "bshadow-l bshadow-gray-7"], {
  variants: {
    open: {
      false: "hidden",
    },
  },
})

// #endregion

import type { CustomCSSProperties } from "../types.ts"

import * as React from "react"

import { cva } from "../cva.ts"

type Context = {
  open: boolean
  toggleSidebar: () => void
}

const Context = React.createContext<Context | null>(null)

export function useSidebar() {
  const context = React.use(Context)
  if (!context) {
    throw new Error("useSidebar must be used within a Sidebar.Root.")
  }

  return context
}

export function Root({ children, defaultOpen = true }: Root.Props) {
  const [open, setOpen] = React.useState(defaultOpen)

  const toggleSidebar = () => {
    setOpen((prev) => !prev)
  }

  const value = {
    open,
    toggleSidebar,
  }

  const rootStyles: CustomCSSProperties = {
    "--sidebar-width": "13.75rem", // 220px
    "--sidebar-width-collapsed": "2.5rem", // 40px
    "--sidebar-width-mobile": "17.5rem", // 280px
    "--sidebar-transition-duration": "0.4s",
  }

  return (
    <Context value={value}>
      <div data-ui="app-container" style={rootStyles} className={classForAppContainer({ open })}>
        {children}
      </div>
    </Context>
  )
}

const classForAppContainer = cva(
  "grid before:content-[''] min-h-full [transition:grid-template-columns_var(--sidebar-transition-duration)]",
  {
    variants: {
      open: {
        true: "grid-cols-[2.5rem,1fr] md:grid-cols-[var(--sidebar-width),1fr]",
        false: "grid-cols-[2.5rem,1fr]",
      },
    },
  },
)

export namespace Root {
  export type Props = {
    children: React.ReactNode
    defaultOpen?: boolean
  }
}

import { cva } from "../cva.ts"
import { useSidebar } from "./sidebar-root.tsx"

export function Footer({ children }: Footer.Props) {
  const { open } = useSidebar()

  return (
    <header data-ui="header" className={classForFooter({ open })}>
      {children}
    </header>
  )
}

const classForFooter = cva("shrink-0 p-1.5 transition-all duration-200", {
  variants: {
    open: {
      true: "opacity-100 visible",
      false: "opacity-0 invisible",
    },
  },
})

export namespace Footer {
  export type Props = {
    children: React.ReactNode
  }
}

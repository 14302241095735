import { useSidebar } from "./sidebar-root.tsx"

export function Toggle(props: Toggle.Props) {
  const { toggleSidebar } = useSidebar()

  return <button type="button" data-ui="toggle" onClick={toggleSidebar} {...props} />
}

export namespace Toggle {
  export type Props = Omit<React.ComponentProps<"button">, "onClick">
}
